import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Location, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { 
  ContentNode, ContentTitle,
  IndicatorValueTable, MainAside, useEntity, useLang, 
  INDICATOR_AREA_IN_SQ_KM, INDICATOR_POPULATION, INDICATOR_POPULATION_CITY, 
  INDICATOR_DENSITY, INDICATOR_GENDER_SPLIT, INDICATOR_MEDIAN_AGE, INDICATOR_AGE_0_TO_19, 
  INDICATOR_AGE_20_TO_49, INDICATOR_AGE_50_PLUS, INDICATOR_GDP, INDICATOR_PER_CAPITA_INCOME, 
  INDICATOR_MEDIAN_HOUSEHOLD_INCOME, Grid, EntityListSection, PlainLinkButton, 
   SliceLayoutBoundary 
} from '@shapeable/ui';

import { PageHeader } from '@shapeable/web';

import { LocationHeaderLayout } from './location-header-layout';
import { BANNER_KNOWLEDGE_HUB, BANNER_OVERLAY } from '../../data';
import { IndicatorValue } from '@shapeable/kelp-forest-alliance-types';
import { filter } from 'lodash';
import { MapPinIcon } from '../elements/map-pin-icon';
import { classNames, formatNumber, locationDMS } from '@shapeable/utils';
import { useMapUtils, EntityValueMapLink } from '@shapeable/maps';
const cls = classNames('location-layout');

// -------- Types -------->

export type LocationLayoutProps = Classable & HasChildren & { 
  entity?: Location;
};

export const LocationLayoutDefaultProps: Omit<LocationLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .shp--slice-layout {
      width: 100%;
    }
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const ContentStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} 0 ${theme.UNIT(7)};
    ${theme.H_DOT_BG()};
    &:first-child { background: none; }
    h1 {
      color: ${theme.COLOR('secondary')};
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  `,
});

const DMSStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0 ${theme.UNIT(4)};
    ${theme.H_DOT_BG_END()};
    width: 100%;
    padding-bottom: ${theme.UNIT(3)};
    color: ${theme.COLOR('dark')};
    display: flex;
    font-size: ${theme.FONT_SIZE(13)};
    align-items: center;

    div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    span, b {
      display: block;
    }

    b {
      padding-top: ${theme.UNIT(1)};
      font-weight:500;
    }

    &:hover {
      .shp--map-marker-icon__fill {
        fill: ${theme.COLOR('link-hover')};
      }
    }
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
  `
});

const DMSIconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(1)};
    width: 56px;
    height: 56px;
  `,
});

const AboutStyles = breakpoints({
  base: css`
  ${ContentStyles}
  `,
});

const MainAsideStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(15)};
    padding: ${theme.UNIT(2)} 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const AsideTitleMobileStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    padding: ${theme.UNIT(6)} ${theme.UNIT(0)} ${theme.UNIT(1)};
    color: ${theme.COLOR('secondary')};
    text-transform: uppercase;
  `,
  desktop: css`
    display: none;
  `
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
  `,
});

const AsideItemStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    margin-bottom: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const CountryStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
    color: ${theme.COLOR('dark')};
    cursor: pointer;
    &:hover {
      color: ${theme.COLOR('link-hover')};
    }
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    color: ${theme.COLOR('secondary')};
    text-transform: uppercase;
  `,
});

const DataStyles = breakpoints({
  base: css`
    ${ContentStyles};
    max-width: 100%;
  `,
});

const TableStyles = breakpoints({
  base: css`
    margin-top: -${theme.UNIT(2)};
    margin-bottom: ${theme.UNIT(4)};
    max-width: 100%;
    span {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  `,
});


const ListSectionStyles = breakpoints({
  base: css`
    padding: 0;
    cursor: pointer;
    a {
      color: ${theme.COLOR('dark')};
      &:hover {
        color: ${theme.COLOR('link-hover')};
        }
    };
  `,
});

const ValuesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    ${ContentStyles};
    padding: ${theme.UNIT(7)} ${theme.UNIT(0)} ${theme.UNIT(4)};
    
  `,
});

const FeedEntriesStyles = breakpoints({
  base: css`
    ${ContentStyles};
    padding-left: 0;
    padding-bottom: 0;
  `,
  desktop: css`
    padding-bottom: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,

  Header: styled(LocationHeaderLayout)`${HeaderStyles}`,
  
  Body: styled.div`${BodyStyles}`,

  Title: styled(ContentTitle)`${TitleStyles}`,

  MainAside: styled(MainAside)`${MainAsideStyles}`,

    Aside: styled.div`${AsideStyles}`,
      AsideTitleMobile: styled(ContentTitle)`${AsideTitleMobileStyles}`,
      Country: styled(EntityValueMapLink)`${CountryStyles}`,
      DMS: styled(PlainLinkButton)`${DMSStyles}`,
        DMSIcon: styled(MapPinIcon)`${DMSIconStyles}`,

    About: styled(ContentNode)`${AboutStyles}`,

    Data: styled.div`${DataStyles}`, 
        Values: styled.div`${ValuesStyles}`,
          Table: styled(IndicatorValueTable)`${TableStyles}`,
  
    FeedEntries: styled.div`${FeedEntriesStyles}`,
      Grid: styled(Grid)`${GridStyles}`,
        ListSection: styled(EntityListSection)`${ListSectionStyles}`,


};    

export const LocationLayout: Shapeable.FC<LocationLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);

  const { description, country, population, populationCity, populationFemale, populationMale,
        feedEntries, medianAge, age0To19, age20To49, age50Plus, areaInSquareKm, densityPerSquareKm,
        gdp, perCapitaIncome, medianHouseholdIncome, organisations } = entity

  const t = useLang()

  const dms = locationDMS(entity);
  const { showEntity } = useMapUtils();

  const dmsOnClick = () => {
    showEntity(entity, {
      showMap: true,
      entityTypeNames: ['Organisation'],
      zoom: 11,
    });
  };

  const totalGenderPopulation = populationMale + populationFemale;

  const percentageMale = (totalGenderPopulation && populationMale) ?
    `${((populationMale / totalGenderPopulation) * 100).toFixed(1)}% M` : '';

  const percentageFemale = (totalGenderPopulation && populationFemale) ?
    `${((populationFemale / totalGenderPopulation) * 100).toFixed(1)}% F` : '';

  const genderSplit = percentageMale && percentageFemale && `${percentageFemale} / ${percentageMale}`;

  const geoDemoValues: IndicatorValue[] = filter([
    areaInSquareKm && {
      id: `area-in-square-km-value`,
      value: formatNumber(areaInSquareKm),
      indicator: INDICATOR_AREA_IN_SQ_KM,
    },
    population && {
      id: `population`,
      value: formatNumber(population),
      indicator: INDICATOR_POPULATION,
    },
    populationCity && {
      id: `population-city`,
      value: formatNumber(populationCity),
      indicator: INDICATOR_POPULATION_CITY,
    },
    densityPerSquareKm && {
      id: `density`,
      value: formatNumber(densityPerSquareKm),
      indicator: INDICATOR_DENSITY,
    }
  ]);

  const genderAgeValues: IndicatorValue[] = filter([
    genderSplit && {
      id: `gender-split`,
      value: genderSplit,
      indicator: INDICATOR_GENDER_SPLIT,
    },
    medianAge && {
      id: `median-age`,
      value: formatNumber(medianAge),
      indicator: INDICATOR_MEDIAN_AGE,
    },
    age0To19 && {
      id: `age-0-19`,
      value: formatNumber(age0To19),
      indicator: INDICATOR_AGE_0_TO_19,
    },
    age20To49 && {
      id: `age-20-49`,
      value: formatNumber(age20To49),
      indicator: INDICATOR_AGE_20_TO_49,
    },
    age50Plus && {
      id: `age-50-plus`,
      value: formatNumber(age50Plus),
      indicator: INDICATOR_AGE_50_PLUS,
    },
  ]);

  const economyValues: IndicatorValue[] = filter([
    gdp && {
      id: `gdp`,
      value: formatNumber(gdp),
      indicator: INDICATOR_GDP,
    },
    perCapitaIncome && {
      id: `per-capita-income`,
      value: formatNumber(perCapitaIncome),
      indicator: INDICATOR_PER_CAPITA_INCOME,
    },
    medianHouseholdIncome && {
      id: `median-household-income`,
      value: formatNumber(medianHouseholdIncome),
      indicator: INDICATOR_MEDIAN_HOUSEHOLD_INCOME,
    }
  ]);

  const hasStatistics = !!geoDemoValues.length || !!economyValues.length || !!genderAgeValues.length;
  
  const pageEntity: Page = {
    id: `page-${entity.id}`,
    banners: [BANNER_KNOWLEDGE_HUB],
  };

  return (
   <My.Container className={cls.name(className)}>


      <PageHeader aspectRatio={{ base: 4 / 1 }} overlayColor={BANNER_OVERLAY} entity={pageEntity}>
      <My.Header entity={entity} />
      </PageHeader>

      <SliceLayoutBoundary>

      <My.Body>
        <My.MainAside 
          aside={
            <My.Aside>
              <My.AsideTitleMobile>{t('Related Information:')}</My.AsideTitleMobile>
              {
                dms && 
                  <My.DMS onClick={dmsOnClick}>
                    <My.DMSIcon />
                      <div>
                        <span>{t('View on Map')}</span>
                        <b>{dms}</b>
                      </div>
                  </My.DMS>
              }
              {
                
                country && <My.Country entity={country} />
              }
            </My.Aside>
          }>

          <My.About title={t('About:')} entity={description} />

            {
              hasStatistics &&
              <My.Data>
                  <My.Title>{t('Statistics:')}</My.Title>
                  <My.Values>
                      <My.Table indicatorCellLabel='Geography and Demography' values={geoDemoValues} />
                      <My.Table indicatorCellLabel='Gender and age' values={genderAgeValues} />
                      <My.Table indicatorCellLabel='Economy' values={economyValues} />
                  </My.Values>
                </My.Data>
            }
          
            {
              organisations &&
                <My.Grid spacing={0} columns={1} items={[
                  !!organisations.length && <My.ListSection imageStyle="logo" useLinks items={organisations} />
                ]} />
            }

          <My.FeedEntries>
            <My.ListSection useLinks showDividers items={feedEntries} />
          </My.FeedEntries>
          

     </My.MainAside>
    </My.Body>
    </SliceLayoutBoundary>
   </My.Container>
  )
};

LocationLayout.defaultProps = LocationLayoutDefaultProps;
LocationLayout.cls = cls;